.image-laptop {
  &.orange, .orange & {
  	background-image: url(../../img/laptop_orange.jpg);
  }

  &.purple, .purple & {
  	background-image: url(../../img/laptop_violet.jpg);
  }

	background-repeat: no-repeat;
  background-size: auto 100%;
  height: 25px;
  width: 50px;
}

.image-puce-infos{
	background-image: url(../../img/information.png);
	background-repeat: no-repeat;
  height: 16px;
  width: 17px;
}

.puce-bleue-droite {
	display: inline-block;
	padding-right: 10px;
	background-image: url(../../img/puce-bleue-droite.png);
	background-repeat: no-repeat;
	background-position: center;
  width: 17px;
  height: 17px;
}

.image-bouton-deplier{
	background-image: url(../../img/avantages_replier.png);
	background-repeat: no-repeat;
	height: 69px;
  width: 240px;
  margin: auto;
}

.image-bouton-replier{
	background-image: url(../../img/avantages_deplier.png);
	background-repeat: no-repeat;
	height: 69px;
  width: 240px;
  margin: auto;
}

.puce-droite {
	display: inline-block;
	padding-right: 10px;
	background-image: url(../../img/puce-violet-droite.png);
	background-repeat: no-repeat;
	background-position: center;
  width: 17px;
  height: 17px;
}

.logo-youtube {
	background-image: url(../../img/youtube.png);
	background-repeat: no-repeat;
  background-size: auto 100%;
	display: inline-block;
  height: 25px;
  width: 53px;
  margin-bottom: -6px;
}

.lyoutube {
	background-image: url(../../img/youtube2.png);
	background-repeat: no-repeat;
	background-size: auto 100%;
	display: inline-block;
	height: 26px;
	width: 60px;
	margin-bottom: -6px;
}
