.obligatoire {
  color: $brand-danger-red;
  font-size: 1.1em;
  top: -0.3em;
}

.has-error, .help-block{
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: $font-size-small;
    line-height: 1.5rem;
}

form {

  label {
    @extend .mer;
    font-weight: normal;
    font-size: initial;
    color: #333333;
  }

  .radio-label {
    color: $black;
    margin-top: 2px;
    font-size: $font-size-large;
    padding-bottom: 0px;
  }

  .checkbox-label {
    color: $black;
    margin-top: 2px;
    font-size: $font-size-large;
    padding-bottom: 0px;
  }
}

body {
  counter-reset: step;
}

#bulletin-contact {

  /* Hide input number arrows */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 0;
  color: $mer;
  padding: 5px 15px;
  margin-right: 10px;
  opacity: 1;
  font-size: 1em;
  line-height: normal;
  font-weight: normal;
  text-shadow: none;
  border: 1px solid #0A74A9;
  background-color: #F5F5F5;
}

#form {
  button {
    font-family: FiraSans-Regular, Arial, Serif;
    /*width: 90%;*/
    font-size: 1.1em;
    background-color: white;
    color: $lesrencontres;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid $lesrencontres;
    border-radius: 0px;
    padding: 15px 20px;

    /* pour les prises de rv matin | midi | soir
    &.left {
      border-radius: 5px 0 0 5px;
    }
    &.middle {
      border-radius: 0;
    }
    &.right {
      border-radius: 0 5px 5px 0;
    }
    */
    &.yesnon {
      width: 60px;
    }

    &.resetsave {
      width: 60px;
    }
  }

  button:focus {
    outline: none;
  }

  button.highlighted {
    border: 1px solid $lesrencontres;
    color: white;
    background-color: $lesrencontres;
  }

  input {
    border: 1px lightgrey solid;
    border-radius: 0px;
    padding: 10px;
  }

  select {
    border: 1px lightgrey solid;
    border-radius: 0px;
    padding: 10px;
  }

  input:focus {
    outline: none;
  }

  select:focus {
    outline: none;
  }

  #bulletin-contact {
    margin: 30px 70px;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: xx-large;
    }

    .form {
      display: flex;
      flex-flow: column;

      .input {
        width: 100%;
        margin: 10px 0;

        span {
          font-weight: normal;
          font-size: initial;
        }

        span.error {
          font-size: 14px;
        }

        .user-interaction {
          margin: 5px 30px 0 0;
          display: flex;

          button {
            margin: 0 15px 0 0;
          }

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }

          span {
            line-height: 42px;
            margin: 0 5px;
          }

          &.hours {
            display: flex;

            button {
              flex: 1;
              margin: 0;
            }
          }
        }

        .date {
          input {
            width: auto;
          }
        }
      }

      .delete-input {
        width: auto;

        button {
          margin-top: 25px;

          &:disabled {
            color: #bbbbbb;
            border-color: #bbbbbb;
          }
        }
      }

      .detached-group {
        background: rgb(238, 238, 238);
        border-radius: 0px;
        padding: 15px 0;

        >span {
          color: $mer;
        }
      }

      .input-group {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }

      .group {
        margin: 20px 0;

        .group-title {
          color: #0A74A9;
          font-weight: bold;
          font-size: 1.3em;
        }
      }

      .multi-inputs {
        .add-input {
          display: flex;
          margin: auto;
          background: $mer;
          color: white;
          border: none;
          font-weight: normal;
          text-transform: none;
          padding: 6px 10px 6px 0;
          line-height: 20px;

          &:disabled {
            background: #bbbbbb;
          }

          span {
            margin: 0 10px;
          }
        }
      }

      .error {
        color: #cb0005;
        font-size: 14px;
        /*margin-top: 5px;*/
      }
    }
  }

  #form-valid {
    .title {
      width: 100%;
      text-align: center;
    }

    .results {
      display: flex;
      margin: 40px 20px;
      text-align: center;

      .result {
        flex: 1;
        position: relative;
        /*min-height: 180px;*/
        padding: 10px 20px;

        .blue {
          font-weight: bold;
          @extend .content;
        }

        .content {
          margin-bottom: 40px;
        }

        button {
          /*position: absolute;*/
          bottom: 0;
        }
      }
    }
  }
}

.home-form {
  .steps {
    display: flex;
    margin: 50px 0;
    padding: 0;

    li {
      flex: 1;
      list-style-type: none;
      text-align: center;
      position: relative;
    }

    li:before {
      counter-increment: step;
      content: counter(step);
      display: block;
      color: #EF7F46;
      font-size: 1.8em;
      font-weight: bold;
      width: 50px;
      height: 50px;
      border: 2px solid $lesrencontres;
      border-radius: 25px;
      margin: auto auto 20px auto;
      line-height: 48px;
      position: relative;
    }

    li:after {
      content: '';
      position: absolute;
      background-color: #ddd;
      height: 1px;
      width: calc(100% - 50px);
      top: 21%;
      left: calc(50% + 25px);
    }

    li.last:after {
      width: 0;
    }
  }
}
