@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  src: url("../fonts/BebasNeue.woff") format("woff");
}

/* stylelint-enable */
/* stylelint-disable */
@font-face {
  font-family: 'FiraSans-Bold';
  font-style: normal;
  src: url("../fonts/FiraSans-Bold.woff") format("woff");
}

/* stylelint-enable */
/* stylelint-disable */
@font-face {
  font-family: 'FiraSans-Light';
  font-style: normal;
  src: url("../fonts/FiraSans-Light.woff") format("woff");
}

/* stylelint-enable */
/* stylelint-disable */
@font-face {
  font-family: 'FiraSans-Regular';
  font-style: normal;
  src: url("../fonts/FiraSans-Regular.woff") format("woff");
}

/* stylelint-enable */
/* stylelint-disable */
@font-face {
  font-family: 'FiraSans-SemiBold';
  font-style: normal;
  src: url("../fonts/FiraSans-SemiBold.woff") format("woff");
 }