@mixin theme($color, $name) {
  &.primary-#{$name}::before {
    background: $color;
  }

  &.primary-#{$name} {
    .left-line, .macaron, .macaron-content, .divider {
      background-color: $color;
      color: #ffffff;
    }

    .subject-content {
      h1 {
        color: $color;
      }
    }

    .more::before {
      border-color: transparent transparent transparent darken($color, 15%);
    }

    .more-content {
      &::before {
        border-color: transparent $color transparent transparent;
      }

      &::after {
        border-color: transparent transparent $color transparent;
      }
    }

    .more .more-content {
      background-color: $color;
    }
  }
}

.page-content {
  display: block;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  display: block;

  width: 100%;
  height: 100%;

  margin: 0px;
  padding: 0px;
  padding-left: $flipbook-vline-margin + $flipbook-vline-width;
  padding-bottom: $flipbook-footer-height;

  overflow-x: hidden;
  overflow-y: auto;

  &::before {
    content: '';
    display: block;

    width: $top-bar-width;
    height: $top-bar-height;

    margin: auto;
    margin-bottom: 15px;

    padding: 0px;

    background: transparent;
  }

  &::after {
    content: '';
    display: block;

    position: absolute;
    bottom: $flipbook-footer-height - floor($flipbook-footer-icon-height / 2);
    right: $flipbook-footer-icon-right;

    width: floor($flipbook-width / 2);
    height: $flipbook-footer-icon-height;

    background-image: url('../img/logo-casden.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right center;
  }

  .left-line {
    display: block;
    position: absolute;

    top: 0px;
    bottom: 0px;
    left: $flipbook-vline-margin;

    width: $flipbook-vline-width;
    height: auto;

    .names {
      display: block;

      margin: 0px;
      padding: 0px;

      text-align: right;

      height: $flipbook-vline-width;
      width: floor($flipbook-height * 0.5);
      overflow: visible;

      -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
      -webkit-transform: rotate(-90deg) translateX(- floor($flipbook-height * 0.532));
      -ms-transform: rotate(-90deg) translateX(- floor($flipbook-height * 0.532));
      transform: rotate(-90deg) translateX(- floor($flipbook-height * 0.532));

      & > p {
        display: block;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        font-size: $flipbook-vline-text-size;
        margin: 0px;
        padding: 0px;
      }

      &.names-big > p {
        font-size: $flipbook-vline-text-size-big;
      }

    }

    .icon {
      margin-top: floor(0.045 * $flipbook-height);
      img {
        display: block;
        width: auto;
        height: $flipbook-vline-icon;
        margin: auto;
        padding: 0px;
      }
    }
  }

  .subject {
    display: block;
    position: relative;

    width: $flipbook-subject-width;
    height: auto;

    border: $flipbook-subject-border-color solid $flipbook-subject-border-depth;

    margin: auto;
    padding: 10px;

    font-size: $flipbook-subject-text-size;
    text-align: justify;

    .subject-content {

      h1 {
        margin: 0px;
        margin-bottom: 10px;

        .bigger {
          display: block;
          font-size: $flipbook-subject-title-bigger-size;
        }

        font-size: $flipbook-subject-title-size;
        font-weight: bold;
        text-align: center;
      }

      tr {
        td {
          text-align: justify;
          vertical-align: middle;

          padding-top: 20px;
          padding-bottom: 20px;

          &.picture {
            text-align: center;
            padding: 0px;
          }
        }

        td + td {
          &, &.picture {
            padding-left: 10px;
          }
        }
      }

      padding-bottom: 20px;
    }/* .subject-content */

    .macaron {
      border-radius: 100%;
      border: solid $flipbook-macaron-outer-border #ffffff;
      margin: 0px;
      padding: $flipbook-macaron-outer-padding;

      width: $flipbook-macaron-size;
      height: $flipbook-macaron-size;

      position: absolute;

      top: - floor($flipbook-subject-border-depth / 2);
      right: - floor($flipbook-subject-border-depth / 2);

      .macaron-content {
        display: block;

        border-radius: 100%;
        border: solid $flipbook-macaron-inner-border #ffffff;

        padding: $flipbook-macaron-inner-padding;
        margin: 0px;

        text-align: center;
        line-height: $flipbook-macaron-size - 2*$flipbook-macaron-outer-padding - 2*$flipbook-macaron-outer-border  - 2*$flipbook-macaron-inner-padding - 2*$flipbook-macaron-inner-border;
        font-size: $flipbook-macaron-text-size;
        font-weight: bold;
        color: #ffffff;

        height: $flipbook-macaron-size - 2*$flipbook-macaron-outer-padding - 2*$flipbook-macaron-outer-border;
        width: $flipbook-macaron-size - 2*$flipbook-macaron-outer-padding - 2*$flipbook-macaron-outer-border;

        p {
          line-height: normal;
          vertical-align: middle;
          display: inline-block;

          margin: 0px;
          padding: 0px;
        }
      }
    } /* .macaron */

    .more {
      display: block;
      position: absolute;
      background-color: transparent;

      bottom: 5px;
      right: -5px;

      width: auto;
      height: auto;

      &::before {
        content: '';
        display: block;
        position: absolute;

        top: -5px;
        right: -5px;

        width: 0;
        height: 0;

        border-style: solid;
        border-width: 5px;

        z-index: 0;
      }

      .more-content {

        $triangle-size: floor($flipbook-more-height / 2);

        &::before {
          content: '';
          display: block;
          position: absolute;

          left: -$triangle-size;
          top: 0px;

          width: 0px;
          height: 0px;

          border-style: solid;
          border-width: 0px $triangle-size $triangle-size 0px;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;

          left: -$triangle-size;
          bottom: 0px;

          width: 0px;
          height: 0px;

          border-style: solid;
          border-width: 0px 0px $triangle-size $triangle-size;
        }

        position: relative;

        color: #ffffff;

        p {
          .bigger {
            font-size: $flipbook-more-text-size;
            font-weight: bold;
          }

          display: inline-block;
          vertical-align: middle;
          text-align: center;

          margin: 0px;
          padding: 0px;

          line-height: normal;
        }

        font-size: $flipbook-more-text-size;

        height: $flipbook-more-height;
        line-height: $flipbook-more-height;

        padding: 0px;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;

        z-index: 1;
      } /* .more-content */

    } /* .more */

    &.type-2 {
      border-color: transparent;

      .macaron {
        top: - floor($flipbook-subject-border-depth / 2);
        left: - floor($flipbook-subject-border-depth / 2);
      }
    } /* .type-2 */

  } /* .subject */

  .divider {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    display: block;

    width: 41px;
    height: 2px;

    padding: 0px;
  } /* .divider */

  @include theme($flipbook-purple, 'purple');
  @include theme($flipbook-dark, 'dark');
  @include theme($flipbook-blue, 'blue');
  @include theme($flipbook-green, 'green');
  @include theme($flipbook-red, 'red');
  @include theme($flipbook-orange, 'orange');
  @include theme($flipbook-deep-blue, 'deep-blue');
  @include theme(#ffffff, 'white');

} /* .page-content */
