.video {
  position: relative;
  //background-color: #000000;
  margin: 0px;
  padding: 0px;

  video {
    margin: 0px;
    padding: 0px;
  }

  img {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  .video-msg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 15px;
    margin: 0px;
    width: auto;
    height: auto;
    color: $white;
    background-color: rgba(0, 0, 0, 0.6);

    overflow-x: hidden;
    overflow-y: hidden;
  }
}

p {
  margin-bottom: $line-height-computed;
}
