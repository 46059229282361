.backdrop {
  display: block;
  position: absolute;

  overflow: auto;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  width: auto;
  height: auto;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.6);
}
