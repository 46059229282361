.backdrop-container {
  display: block;
  position: absolute;

  left: 0px;
  top: 0px;

  width: 100%;
  height: 100%;

  min-width: $flipbook-width;
  min-height: $flipbook-height + $flipbook-tools-height;

  overflow: hidden;
}

.flipbook-container {
  display: block;
  position: absolute;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: $flipbook-tools-height;

  width: auto;
  height: auto;

  margin: 0px;
  padding: 0px;

  overflow: visible;

  background-color: transparent;
}

.flipbook-tools {
  display: block;
  position: absolute;

  left: 0px;
  right: 0px;
  bottom: 0px;

  width: auto;
  height: $flipbook-tools-height;
  line-height: $flipbook-tools-height;

  vertical-align: middle;

  margin: 0px;
  padding: 0px;
  padding-top: 5px;

  overflow: visible;

  background-color: transparent;

  .btn {
    font-size: 26px;
  }

  .snap-right {
    position: absolute;
    display: block;

    right: 20px;
    top: 0px;

    height: $flipbook-tools-height;
    width: auto;
  }

  .snap-left {
    position: absolute;
    display: block;

    left: 20px;
    top: 0px;

    height: $flipbook-tools-height;
    width: auto;
  }
}

.flipbook {
  display: block;
  position: relative;

  width: $flipbook-width;
  height: $flipbook-height;

  margin: auto;
  padding: 0px;

  overflow: visible;

  .page {
    background-color: #ffffff;
    overflow: visible;

    &.odd:after, &.even:after {
      content: '\e095';

      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      display: block;

      position: absolute;
      bottom: 0px;

      width: 50px;
      height: 50px;

      line-height: 50px;

      vertical-align: middle;
      text-align: center;

      background-color: transparent;
      color: #ffffff;
      text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2),
                   0px 1px 0px rgba(0, 0, 0, 0.5),
                   1px 0px 0px rgba(0, 0, 0, 0.5),
                   0px -1px 0px rgba(0, 0, 0, 0.5),
                   -1px 0px 0px rgba(0, 0, 0, 0.5),
                   1px 1px 0px rgba(0, 0, 0, 0.5),
                   1px -1px 0px rgba(0, 0, 0, 0.5),
                   -1px 1px 0px rgba(0, 0, 0, 0.5),
                   -1px -1px 0px rgba(0, 0, 0, 0.5);
    }

    &.odd:after {
      right: 0px;
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }

    &.even:after {
      left: 0px;
    }
  }

  .green {
    color: $flipbook-green;
  }

}


.flipbook-centerer {
  display: block;
  position: absolute;

  top: 50%;
  left: 50%;

  width: auto;
  height: auto;

  overflow: visible;

  .flipbook {
    margin-top: - floor($flipbook-height / 2);
    margin-left: - floor($flipbook-width / 2);
  }
}
