@mixin card-theme($name, $color) {
	&.card-#{$name} {
    border-color: $color;
		.card-header::before {
			background-color: $color;
		}
		.card-header {
			color: $color;
		}
	}
}

.card {
  position: relative;
  background-color: $card-background-color;
  /*@include box-shadow(0px 0px 5px 0px rgba(19, 19, 19, 0.3));*/
  border: 1px solid rgba(160, 160, 160, 0.5);
  width: 100%;
  border-radius: 0px;

  margin-bottom: $line-height-computed;
  padding: $padding-large-vertical $padding-large-horizontal;
  /*padding: $padding-xs-vertical $padding-xs-horizontal;*/

  @media (min-width: $screen-sm-min) {
    padding: $padding-large-vertical $padding-large-horizontal;
    /*padding: $padding-small-vertical $padding-small-horizontal;*/
  }

  @media (min-width: $screen-md-min) {
    padding: $padding-base-vertical $padding-base-horizontal;
  }

  @media (min-width: $screen-lg-min) {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  &.no-padding, .no-padding & {
    padding: 0px;
  }

  &.card-dark {
    background-color: #000007;
  }

  &.card-header {
    margin-top: 0px;
  }

  .snap-bottom {
    position: absolute;
    bottom: $padding-xs-vertical;
    left: $padding-xs-horizontal;
    right: $padding-xs-horizontal;
    width: auto;

    @media (min-width: $screen-sm-min) {
      bottom: $padding-small-vertical;
      left: $padding-small-horizontal;
      right: $padding-small-horizontal;
    }

    @media (min-width: $screen-md-min) {
      bottom: $padding-base-vertical;
      left: $padding-base-horizontal;
      right: $padding-base-horizontal;
    }

    @media (min-width: $screen-lg-min) {
      bottom: $padding-large-vertical;
      left: $padding-large-horizontal;
      right: $padding-large-horizontal;
    }
  }

	.card-header {
    font-size: $font-size-h3;

    font-family: BebasNeue, Arial, Serif;
    text-align: center;

    margin-top: 20px;
    margin-bottom: $line-height-computed;

    @media (max-width: $screen-lg-min) {
      margin-left: 20px;
      margin-right: 20px;
    }

    /* Petite barre au-dessus du titre. KN : j'ai mis display: none */
    &::before {
      content: '';
      display: none;

      height: 10px;
      width: 200px;

      background-color: $gray;

      margin: auto;
      margin-top: -$padding-xs-vertical;
      margin-bottom: 40px;

      @media (min-width: $screen-sm-min) {
        margin-top: -$padding-small-vertical;
      }

      @media (min-width: $screen-md-min) {
        margin-top: -$padding-base-vertical;
      }

      @media (min-width: $screen-lg-min) {
        margin-top: -$padding-large-vertical;
      }
    }
  }

  .card-footer {
    margin-bottom: ceil($line-height-computed/2);
    font-size: $font-size-large;

    @media (min-width: $screen-sm-min) {
      margin-bottom: ceil($line-height-computed/4);
    }

    hr {
      margin-bottom: 5px;
    }

    table {
      width: 100%;
    }

    td {
      vertical-align: middle;
    }
  }

	@include card-theme('orange', $orange);
	@include card-theme('purple', $purple);
	@include card-theme('blue', $blue);
  @include card-theme('pink', $pink);

/***********************************/
/* Nouvelle identitée Octobre 2018 */
/***********************************/
  @include card-theme('royal', $royal);
  @include card-theme('vert', $vert);
  @include card-theme('rouge', $rouge);
  @include card-theme('mer', $mer);
  @include card-theme('lesrencontres', $lesrencontres);
  @include card-theme('gris', $gris);
  @include card-theme('jaune', $jaune);

}
