.fill, .include {
  display: block;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  &.primary-purple {
    background-color: $flipbook-purple;
  }

  &.primary-white {
    background-color: #ffffff;
  }

  &.primary-dark {
    background-color: $flipbook-dark;
  }

  &.primary-green {
    background-color: $flipbook-green;
  }

  &.primary-deep-blue {
    background-color: $flipbook-deep-blue;
  }
}
