.connection-indicator {
  @extend a;

  position: fixed;
  top: 0px;
  right: floor($grid-gutter-width/2);
  width: 100px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 5px 5px;
  color: $white;

  overflow-x: hidden;
  overflow-y: hidden;

  &.disabled-indicator {
    display:none;
  }

  &.visible-indicator {
    height: 30px;

    @include transition(height 0.2s);
  }

  &.hidden-indicator {
    height: 0px;

    @include transition(height 0.2s);
  }

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}
