.footer {
  .column-container {
    display: flex;
    flex-flow: column;
  }

  .row-container {
    display: flex;
    flex-flow: row wrap;

    .row-item {
      width: 50%;

      img {
        margin: 5px 7% 5px 0;
      }
    }
  }

  .mentions {
    margin-bottom: 20px;
  }

  .block-one {
    flex: 1;

    .row-item {
      width: 100%;
    }
  }
  .block-two {
    flex: 2;
  }

  .center {
    text-align: center
  }

  hr {
    border-color: #dddddd;
    color: #dddddd;
  }

  a {
    color: #303030;
    text-decoration: none;
    text-decoration-color: #303030;
  }
  a:hover {
    color: #303030;
    text-decoration: underline;
    text-decoration-color: #303030;
  }
}
