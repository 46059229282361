@mixin theme($color, $name) {
  &.primary-#{$name}, &.secondary-#{$name}:before {
    background: $color;
  }

  &.primary-#{$name} {
    .block {
      border-color: $color;
      color: $color;
    }
  }

  &.secondary-#{$name} {
    h1 {
      color: $color;
    }

    .content {
      background-color: $color;
    }
  }
}

@mixin block-theme($color, $name) {

}

.chapter {
  display: block;

  width: 100%;
  height: 100%;

  margin: 0px;
  padding: 0px;
  padding-bottom: $flipbook-footer-height;

  overflow-x: hidden;
  overflow-y: auto;

  &::before {
    content: '';
    display: block;

    width: $top-bar-width;
    height: $top-bar-height;

    margin: auto;
    margin-bottom: $flipbook-chapter-top-margin;

    padding: 0px;

    background: transparent;
  }

  .footer {
    content: '';
    display: block;

    position: absolute;

    width: auto;
    height: $flipbook-footer-height;

    background-color: #ffffff;

    left: 0px;
    right: 0px;
    bottom: 0px;

    margin: 0px;
    padding: 0px;

    &::after {
      content: '';
      display: block;

      position: absolute;
      bottom: $flipbook-footer-height - floor($flipbook-footer-icon-height / 2);
      right: $flipbook-footer-icon-right;

      width: floor($flipbook-width / 2);
      height: $flipbook-footer-icon-height;

      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: right center;
      background-image: url('../img/logo-casden.png');
    }
  }

  @include theme($flipbook-purple, 'purple');
  @include theme($flipbook-dark, 'dark');
  @include theme($flipbook-blue, 'blue');
  @include theme($flipbook-green, 'green');
  @include theme($flipbook-red, 'red');
  @include theme($flipbook-orange, 'orange');
  @include theme($flipbook-deep-blue, 'deep-blue');
  @include theme(#ffffff, 'white');

  .chapter-icon {
    text-align: center;
    img {
      height: $flipbook-chapter-icon-height;
      margin-bottom: $flipbook-chapter-title-margin;
    }
  }

  .chapter-title {
    text-align: center;
    color: #ffffff;
    font-size: $flipbook-chapter-title-bigger-size;
    font-weight: bold;
  }

  .chapter-subtitle {
    text-align: center;
    color: #ffffff;
    font-size: $flipbook-chapter-title-size;
    font-weight: bold;
    margin-bottom: $flipbook-chapter-title-margin;
  }

  &.chapter-big {
    &::before {
      margin-bottom: $flipbook-chapter-big-top-margin;
    }

    .chapter-icon {
      text-align: center;
      img {
        height: $flipbook-chapter-icon-big-height;
        margin-bottom: $flipbook-chapter-title-big-margin;
      }
    }

    .chapter-title {
      text-align: center;
      color: #ffffff;
      font-size: $flipbook-chapter-big-title-bigger-size;
      font-weight: bold;
    }
  }


  .content {
    display: block;

    padding: $flipbook-block-margin;
    height: auto;

    margin: auto;
    box-sizing: border-box;

    .block-line {
      height: $flipbook-block-height;
      text-align: center;
      font-size: 0px;

      .block {
        cursor: pointer;

        letter-spacing: normal;
        display: inline-block;

        height: $flipbook-block-height;
        width: $flipbook-block-width;

        border-style: dotted;
        border-width: 1px;

        line-height: $flipbook-block-height;

        .block-content {
          line-height: normal;
          vertical-align: middle;
          display: inline-block;

          .block-icon {
            height: $flipbook-block-icon-height;
          }

          .bigger {
            font-size: $flipbook-block-bigger-text-size;
            font-weight: bold;
          }

          font-size: $flipbook-block-text-size;
        }

        & + .block {
          margin-left: $flipbook-block-margin;
        }
      }

      & + .block-line {
        margin-top: $flipbook-block-margin;
      }
    }
  }

  .content-2 {
    width: $flipbook-block-margin*3 + $flipbook-block-width*2;
    text-align: center;
  }

  .content-3 {
    width: $flipbook-block-margin*4 + $flipbook-block-width*3;
    text-align: center;
  }

}
