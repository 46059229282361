html, body {
  display: block;
  position: absolute;

  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  width: auto;
  height: auto;

  margin: 0px;
  padding: 0px;
  padding-bottom: 100px;
}

body {
  background-color: $app-background-color;
  font-family: FiraSans-Regular, Arial, Serif;
  line-height: 2.6rem;
  overflow: auto;
  overflow-y: scroll;
}
