#emprunt {
  .projets {
    width: 100%;
    .two-cols {
      display: flex;
      flex-flow: row;
      .rows-container {
        flex: 1;
        display: flex;
        flex-flow: column;
        .text {
          margin: 15px 30px;
        }
      }
    }
  }

  .offres {
    width: 100%;
    text-align: center;
    margin: 15px 0;
  }

  .plus {
    /* display: flex;*/
    /*flex-flow: column;*/
    align-items: center;
    border: 1px solid $rouge;
    border-radius: 0px;
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 30px;
    position: relative;

    .header {
      color: $rouge;
      margin-bottom: 10px;
    }
  }
  /*
  .plus:after {
    display: block;
    width: 0;
    height: 0;
    content: '';
    bottom: 100%;
    left: calc(50% - 10px);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent $rouge transparent;
    position: absolute;
  }
  */
}
