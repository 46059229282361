.pink {
  color: $pink;
}

.large {
  font-size: $font-size-large;
}

.white {
  color: #ffffff;
}

.orange {
  color: $orange;
}

.text-bold {
  font-weight: bold;
}

.blue {
  color: $blue;
}

.purple {
  color: $purple;
}

.vert_success_text {
  color: $state-success-text;
}

/***********************************/
/* Nouvelle identitée Octobre 2018 */
/***********************************/
.royal {
  color: $royal;
}

.vert {
  color: $vert;
}

.rouge {
  color: $rouge;
}

.mer {
  color: $mer;
}

.lesrencontres {
    color: $lesrencontres;
  }

.gris {
  color: $gris;
}

.jaune {
  color: $jaune;
}

/***********************************/


.lien-casden {
  font-size: 18px;
  font-family: 'FiraSans-Regular', Arial, Serif;
  color: $blue;
}

hr.orange,
.orange hr {
  color: $orange;
  border-top: dotted 1px;
}

hr.purple,
.purple hr {
  color: $purple;
  border-top: dotted 1px;
}

.full-width {
  width: 100%;
}

.fill-white {
  background-color: $white;
}

.credit-engage {
    font-size: $font-size-large;;
    font-weight: bold;
  }

.mentions-legales {
  margin-top: 70px;
}

.mentions-legales p {
  font-size: 12px;
  text-align: justify;
  font-weight: normal;
  line-height: 14px;
  width: 100%;
}

.mentions-legales p+p {
  margin-top: 10px;
}

.as-block {
  display: block;
}

.lien-form {
  text-align: right;
  font-size: 18px;
  font-family: FiraSans-Regular, Arial, Serif;
  color: $blue;
}

.lien-conseiller {
  font-size: 18px;
  font-family: FiraSans-Regular, Arial, Serif;
  margin-top: -5px;
}

.modal-header {
  position: relative;
  padding: 0;
}

.close {
  position: absolute;
  margin: 0;
  top: -20px;
  right: -23px;
}

.casden-reject:before {
  content: "X";
  color: white;
}

.padding-casden-footer {
  padding-bottom: 50px;
}

a {
  color: #00baf2;
}

a:hover {
  color: #00baf2;
  text-decoration: none;
  cursor: pointer;
}

.icon-immobilier {
  width: 40px;
  height: 40px;
  background-image: url(../../img/maison.png);
  background-repeat: no-repeat;
}

.icon-travaux {
  width: 40px;
  height: 40px;
  background-image: url(../../img/travaux.png);
  background-repeat: no-repeat;
}

.icon-voiture {
  width: 40px;
  height: 40px;
  background-image: url(../../img/voiture.png);
  background-repeat: no-repeat;
}

.icon-tirelire {
  width: 40px;
  height: 40px;
  background-image: url(../../img/epargne.png);
  background-repeat: no-repeat;
}

.text-bulletinok {
  text-align: center;
  font-size: 18px;
}

.radio-label {
  padding: 5px;
  font-size: 15px;
}

.data-inscription-empty {
  font-size: 20px;
  color: black;
  padding: 30px;
}

.prog123-video {
  margin-left: 45px;
}

video {
  width: 100%;
  height: 100%;
}

.text-medium {
  font-size: 16px;
}
