@import "bootstrap_variables";
@import "vars";

@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons",
        // Core CSS
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons",
        // Components
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close",
        // Components w/ JavaScript
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel",
        // Utility classes
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities",
        "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

.btn.btn-touch {
  @include button-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large * 2,
    $line-height-large,
    $btn-border-radius-large
  );

  min-width: 90px;
  height: 90px;
  line-height: 90px;

  padding: 0px;
  text-align: center;
}

@import "fonts",
        "text",
        "connectionIndicator",
        "cacheManager",
        "card",
        "body",
        "header",
        "board",
        "footer",
        "contenus",
        "img",
        "form",
        "video",
        "chkbox",
        "select",
        "flipbook/flipbook",
        "mentionsLegales",
        "avantages",
"emprunt";
