@mixin theme($color, $name) {
  &.primary-#{$name}, &.secondary-#{$name}:before {
    background: $color;
  }

  &.secondary-#{$name} {
    h1 {
      color: $color;
    }

    .content {
      background-color: $color;
    }
  }
}

@mixin block-theme($color, $name) {
  &.block-#{$name} {
    background-color: $color;
    color: #ffffff;
  }
}

.summary {
  display: block;

  width: 100%;
  height: 100%;

  margin: 0px;
  padding: 0px;

  overflow-x: hidden;
  overflow-y: auto;

  &:before {
    content: '';
    display: block;

    width: $top-bar-width;
    height: $top-bar-height;

    margin: auto;
    margin-bottom: 48px;

    padding: 0px;

    background: transparent;
  }

  &:after {
    content: '';
    display: block;

    position: absolute;
    bottom: $flipbook-footer-height - floor($flipbook-footer-icon-height / 2);
    right: $flipbook-footer-icon-right;

    width: floor($flipbook-width / 2);
    height: $flipbook-footer-icon-height;

    background-image: url('../img/logo-casden-flipbook.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right center;
  }

  @include theme($flipbook-purple, 'purple');
  @include theme(#ffffff, 'white');

  h1 {
    margin-top: $flipbook-summary-title-margin;
    margin-bottom: $flipbook-summary-title-margin;

    font-size: $flipbook-summary-title-size;
    font-weight: bold;

    text-transform: uppercase;
    text-align: center;
  }

  .content {
    display: block;

    padding: $flipbook-block-margin;
    width: $flipbook-block-margin * 3 + 2 * $flipbook-block-width;
    height: auto;

    margin: auto;
    box-sizing: border-box;

    .block-line {
      height: $flipbook-block-height;

      .block {
        display: block;
        float: left;

        cursor: pointer;

        height: $flipbook-block-height;
        width: $flipbook-block-width;

        line-height: $flipbook-block-height;
        text-align: center;

        .block-content {
          line-height: normal;
          color: #ffffff;
          vertical-align: middle;
          display: inline-block;

          .block-icon {
            height: $flipbook-block-icon-height;
          }

          .bigger {
            font-size: $flipbook-block-bigger-text-size;
            font-weight: bold;
          }

          font-size: $flipbook-block-text-size;
        }

        & + .block {
          margin-left: $flipbook-block-margin;
        }

        @include block-theme($flipbook-blue, 'blue');
        @include block-theme($flipbook-green, 'green');
        @include block-theme($flipbook-red, 'red');
        @include block-theme($flipbook-orange, 'orange');
        @include block-theme($flipbook-deep-blue, 'deep-blue');

        &.block-dotted-purple {
          border: dotted 1px $flipbook-purple;
          .block-content {
            color: $flipbook-purple;
          }
        }
      }

      & + .block-line {
        margin-top: $flipbook-block-margin;
      }
    }

  }

}
