.cache-manager {

  @include gradient-vertical(#101010, #222222, 0px, floor($grid-gutter-width/2));

  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;

  width: auto;
  height: auto;

  background-color: #222222;
  color: $white;
  /*font-size: 2em;*/

  border-top: #000000 solid 1px;

  overflow-x: hidden;
  overflow-y: hidden;

  .row {
    padding-top: floor($grid-gutter-width/2);
    padding-bottom: floor($grid-gutter-width/2);
  }

  @include transition(bottom 2s);

  &.hidden-manager {
    bottom: -200px;
    @include transition(bottom 2s);
  }

  a {
    &.glyphicon {
      text-decoration: none;
    }

    text-decoration: underline;
    color: $white;
  }
}
