.content .card::after {
  content: none;
}

.board {


.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
}

.button2 {border-radius: 4px;}

  .category.card {
    height: 200px;
    cursor: pointer;

    * {
      cursor: pointer;
    }

    &::after {
      content:'';
      position:absolute;

      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;

      width: auto;
      height: auto;

      background-color: rgba(255, 255, 255, 0);


    }

    &.disabled::after {
      content:'';
      position:absolute;

      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;

      width: auto;
      height: auto;

      background-color: rgba(255, 255, 255, 0.8);

      @include transition(background-color 0.4s);
    }

    .empty, .text-middle {
      width: 100%;
      color: $light-gray;
      font-weight: bold;
      font-size: $font-size-h3;
      vertical-align: middle;
      text-align: center;

      height: 200px - $padding-xs-vertical*2;
      line-height: 200px - $padding-xs-vertical*2;

      @media (min-width: $screen-sm-min) {
        height: 200px - $padding-small-vertical*2;
        line-height: 200px - $padding-small-vertical*2;
      }

      @media (min-width: $screen-md-min) {
        height: 200px - $padding-base-vertical*2;
        line-height: 200px - $padding-base-vertical*2;
      }

      @media (min-width: $screen-lg-min) {
        height: 200px - $padding-large-vertical*2;
        line-height: 200px - $padding-large-vertical*2;
      }
    }

    h1 {
      &:first-child {
        margin-top: 0px;
      }

      /*font-size: $font-size-h3;*/
      font-size: 22px;
      font-family: BebasNeue, Arial, Serif;
      line-height: 1.2em;


      @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
        font-size: $font-size-h2;
        font-family: BebasNeue, Arial, Serif;
      }
    }
  }
}
