$light-gray: #dddddd;

$blue: #00baf2;
$purple: #9C64A6;
$pink: #FD428E;
$orange: #FF7C00;
$white: #FFFFFF;
$black: #333333;

/***********************************/
/* Nouvelle identitée Octobre 2018 */
/***********************************/
$royal: #79BCD6; // La casden une banque différente
$vert: #61A0A7;
$rouge: #CE5F65;
$mer: #0A74A9;
$gris: #F5F5F5;
$jaune: #E4A854;
$lesrencontres: #006A7D;

// -------------------------------------------------------- APP
$app-background-color: #ffffff;

// -------------------------------------------------------- CARD
$card-background-color: #ffffff;

$text-color: $black;
