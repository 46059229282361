#avantages {
  .columns {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }

  .item {
    flex: 1;
    background-color: #f6f6f6;
    max-width: 48%;
    padding: 30px;
    border:1px solid #d0d0d0;

    .header {
      color: $mer;
      width: 100%;
      display: flex;
      flex-flow: row;
      justify-content: center;
      margin-bottom: 30px;

      span {
        margin-left: 10px;
      }
    }

    li {
      margin-bottom: 15px;
    }
  }

  .plus {
    display: flex;
    flex-flow: column;
    align-items: center;
    border: 1px solid $mer;
    border-radius: 0px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;

    .header {
      color: $mer;
      margin-bottom: 10px;
    }
  }

  .plus:after {
    display: block;
    width: 0;
    height: 0;
    content: '';
    bottom: 100%;
    left: calc(50% - 10px);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent $mer transparent;
    position: absolute;
  }

}
