header {
  @extend .row;

  .content {
    @extend .col-xs-12;
  }

  .brand img {
    margin-left: ceil($line-height-computed * 0.5);
    height: 80px;

    @media (max-width: $screen-sm-min) {
      height: 50px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
      height: 70px;
    }
  }

  .baseline img {
    @media (max-width: $screen-md-min)
    {
      width: 100%;
    }
  }
}
