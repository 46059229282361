#mentions-legales {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  text-align: left;

  .mentions-legales-modal {
    margin: auto;
    background-color: white;
    padding: 40px 60px;
    box-shadow: 2px 2px 2px black;

    .close-button {
      text-align: right;
      cursor: pointer;
      color: $mer;
    }
  }
}
