iframe {
  border: 1px solid #000000;
}

/* La casden une banque différente */
.card[name="casden"] {
    display: inline-block;
    background-image: url(../../img/svg/icon_banquedifferente.svg);
    background-repeat: no-repeat;
    background-position: center 86px;
    background-size: 100px;
    background-color: $royal;
}

.content[name="casden"] {
  .zone-bleue {
    background-color: #009ee0;
    margin-top: 5px;

    td {
      padding-top: 10px;
      padding-bottom: 20px;
    }

    ul {
      margin: 0px;
      padding: 0px;
      margin-left: 20px;
    }

    li {
      padding-bottom: 25px;
      font-size: 1.2em;
    }
  }

  .mentionpedago {
    font-size: 20px;
    margin: 20px 0;
  }
}

/* Un système d'épargne */
.card[name="epargne"] {
    display: inline-block;
    background-image: url(../../img/svg/icon_systeme_epargne.svg);
    background-repeat: no-repeat;
    background-color: $vert;
    background-size: 290px;
    background-position: center 80px;
}

/* des offres au service des mes projets */
.card[name="emprunt"] {
    display: inline-block;
    background-image: url(../../img/svg/icon_offres.svg);
    background-repeat: no-repeat;
    background-position: center 75px;
    background-color: $rouge;
    background-size: 270px;
}

/* faites le plein d'avantage */
.card[name="avantage"] {
    display: inline-block;
    background-image: url(../../img/svg/icon_avantages.svg);
    background-repeat: no-repeat;
    background-position: center 75px;
    background-size: 125px;
    background-color: $mer;
}

.content[name="avantage"] {
  .outil {
    margin-bottom: $line-height-computed;
  }

  h3{
    font-size: 18px;
    font-weight: bold;
  }
}

/* Bulletin de contact - Rencontres */
.card[name="formulaire"] {
    position: relative;
    display: inline-block;
    background-image: url(../../img/svg/icon_lesrencontres.svg);
    background-color: $lesrencontres;
    background-repeat: no-repeat;
    background-size: 285px;
    background-position: center 90px;

  .sub-title {
    font-size: $font-size-h4;
    padding-bottom: 5px;
  }
}

.content[name="formulaire"] {
  h1 {
    font-size: $font-size-h3;
    font-weight: bold;
  }

  .link-exit {
    @extend .large;
    padding-top: 15px;
    display: inline-block;
    line-height: 60px;
  }

  .titre-formulaire {
    line-height: 60px;
    vertical-align: top;
    padding-top: 15px;
    padding-bottom: 15px;

    * { float: left; }

    .blue {
      font-weight: normal;
    }
  }
}


/* Ressources gratuites */
.card[name="outils"] {
    display: inline-block;
    background-image: url(../../img/svg/icon_ressources.svg);
    background-repeat: no-repeat;
    background-position: center 70px;
    background-size: 110px;
    background-color: $jaune;
}

.content[name="outils"] {
  .outil {
    margin-bottom: $line-height-computed;
  }

  h3{
    font-size: 18px;
    font-weight: bold;
  }
}
