// Taille du flipbook
$flipbook-width: 1200px; // 800
$flipbook-height: 766px; // 508

$flipbook-tools-height: 180px;

// Position du logo Casden
$flipbook-footer-height: floor($flipbook-height * 0.05); // 28
$flipbook-footer-icon-height: floor($flipbook-height * 0.05);
$flipbook-footer-icon-right: floor($flipbook-width * 0.025);

// Ligne verticale dans les pages
$flipbook-vline-width: floor(0.05 * $flipbook-width);
$flipbook-vline-margin: floor(0.02 * $flipbook-width);
$flipbook-vline-text-size: floor(0.0116 * $flipbook-width);
$flipbook-vline-text-size-big: floor(0.015 * $flipbook-width);
$flipbook-vline-icon: floor(0.052 * $flipbook-height);

// Petite barre colorée en haut des pages
$top-bar-width: floor(0.16 * $flipbook-width);
$top-bar-height: floor(0.013 * $flipbook-height);

// Sommaire
$flipbook-summary-title-margin: floor(0.09 * $flipbook-height);
$flipbook-summary-title-size: floor(0.05 * $flipbook-height);

// Chapitres
$flipbook-chapter-icon-height: floor(0.16 * $flipbook-height);
$flipbook-chapter-title-bigger-size: floor(0.026 * $flipbook-height);
$flipbook-chapter-title-size: floor(0.026 * $flipbook-height);
$flipbook-chapter-title-margin: floor(0.05 * $flipbook-height);
$flipbook-chapter-top-margin: floor(0.09 * $flipbook-height);

$flipbook-chapter-big-top-margin: floor(0.26 * $flipbook-height);
$flipbook-chapter-icon-big-height: floor(0.23 * $flipbook-height);
$flipbook-chapter-title-big-margin: floor(0.13 * $flipbook-height);
$flipbook-chapter-big-title-bigger-size: floor(0.039 * $flipbook-height);

// Blocs des sommaires et chapitres
$flipbook-block-width: floor(0.14 * $flipbook-width);
$flipbook-block-height: floor(0.17 * $flipbook-height);
$flipbook-block-margin: floor(0.015 * $flipbook-height);

$flipbook-block-bigger-text-size: floor(0.015 * $flipbook-height);
$flipbook-block-text-size: floor(0.015 * $flipbook-height);

$flipbook-block-icon-height: floor(0.065 * $flipbook-height);

// Cadre outils
$flipbook-subject-width: floor(0.35 * $flipbook-width);
$flipbook-subject-border-depth: floor(0.03 * $flipbook-height);
$flipbook-subject-border-color: #e5e5e5;

// Bulle bleue en haut des blocs outils
$flipbook-macaron-size: floor(0.08 * $flipbook-height);
$flipbook-macaron-outer-border: 2px;
$flipbook-macaron-outer-padding: 2px;
$flipbook-macaron-inner-border: 1px;
$flipbook-macaron-inner-padding: 5px;

$flipbook-macaron-text-size: floor(0.01 * $flipbook-height);

// Sujets
$flipbook-subject-title-bigger-size: floor(0.013 * $flipbook-height);
$flipbook-subject-title-size: floor(0.013 * $flipbook-height);
$flipbook-subject-text-size: floor(0.012 * $flipbook-height);

// Petit drapeau à la fin d'une rubrique
$flipbook-more-height: floor(0.04 * $flipbook-height);
$flipbook-more-text-size: floor(0.012 * $flipbook-height);

// Couleurs du flipbook
//$flipbook-purple: #90489c;
$flipbook-purple: #f6882b;
$flipbook-dark: #000007;
$flipbook-blue: #19bcbe;
$flipbook-green: #51bb6b;
$flipbook-red: #f05764;
$flipbook-orange: #f6882b;
$flipbook-deep-blue: #426597;
