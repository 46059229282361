/* Checkbox et radios
----------------------------------- */
.icheckbox,
.iradio {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(../../img/chkbox.png) no-repeat;
    border: none;
    cursor: pointer;

    margin-top: -15px;
}

.iradio,
.icheckbox {
    background-position: -40px 0;
}

.iradio.hover,
.icheckbox.hover {
	background-position: -80px 0;
}

.iradio.checked.hover,
.icheckbox.checked.hover {
	background-position: -100px 0;
}

.iradio.checked,
.icheckbox.checked {
	background-position: -60px 0;
}

.iradio.disabled,
.icheckbox.disabled {
	background-position: 0 0;
    cursor: default;
}

.iradio.checked.disabled,
.icheckbox.checked.disabled {
	background-position: -20px 0;
	cursor: default;
}

.has-error .iradio,
.has-error .icheckbox {
    background-position: -160px 0;
}

.has-error .iradio.hover,
.has-error .icheckbox.hover {
	background-position: -200px 0;
}

.has-error .iradio.checked.hover,
.has-error .icheckbox.checked.hover {
	background-position: -220px 0;
}

.has-error .iradio.checked,
.has-error .icheckbox.checked {
	background-position: -180px 0;
}

.has-error .iradio.disabled,
.has-error .icheckbox.disabled {
	background-position: -120px 0;
    cursor: default;
}

.has-error .iradio.checked.disabled,
.has-error .icheckbox.checked.disabled {
	background-position: -140px 0;
	cursor: default;
}

/* line checkbox, line radio
----------------------------------- */
.line-checkbox,
.line-radio {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-left: 10px;
  padding: 5px;
  padding-left: 20px;
  border: 1px solid #dddddd;
  width: 300px;
  border-radius:4px;
  text-align:left;
  cursor: pointer;
  @extend .large;
  color: $black;

  &.hover {
  	background-color: #ddedf9;
  	border-color: #00adee;
  }

  &.checked.hover { }

  &.checked {
    background-color: #00adee;
    border-color: #00adee;
    color: #ffffff;
  }

  &.disabled {
    cursor: default;
    border-color: #dddddd;
    color: #dddddd;
  }

  &.checked.disabled {
    border-color: #dddddd;
    color: #ffffff;
    background-color: #dddddd;
    cursor: default;
  }
}
